import {Injectable, Inject, NgZone} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router, UrlTree} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private localAuthCB: Function
  constructor(
    @Inject ('baseWebcomref') private webcomRef,
    @Inject('publicRoutes') private publicRoutes: (string)[],
    @Inject('defaultNotAuthRoute')  private defaultNotAuthRoute: string,
    @Inject('defaultAuthRoute') private defaultAuthRoute: string,
    @Inject('idHandler') private idHandler,
    private router: Router,
    private _ngZone: NgZone
  ) {

    console.log('auth guard constructed');

    this._ngZone.run(() => this.localAuthCB = (error, auth)=>{

      const requestedRoute = this._ngZone.run(() => this.router.url.replace(/^\/+/, ''));
      console.log('from webcom  addAuthCallback : authcallback triggered. requestedRoute=', requestedRoute);
  
      if (error || !auth) { // if user is NOT connected
        if (error) { console.log('Error auth: ' + error); } else { console.log('Not connected,', requestedRoute); }
  
        if (this.publicRoutes.includes(requestedRoute)) {
          // ngZone is required because of the redirect func executes in callback, it need to run in Angular zone
          console.log('not connected and requested route is public => ok ', requestedRoute);
          // this._ngZone.run(() => this.router.navigate([requestedRoute]));
        } else {
          console.log('not connected and requested route is private', requestedRoute, 'redirect to default : ', this.defaultNotAuthRoute);
          this._ngZone.run(() => this.router.navigate(['/' + this.defaultNotAuthRoute]));
        }
      } else {
        const userIdentity = auth.providerUid;
        console.log('Authenticated: ' + userIdentity);
        // TODO : if the user have been invited to collaborate to a retro, the application must register her.his account to the retro_owner.
  
        if (this.publicRoutes.includes(requestedRoute)) {
          // ngZone is required because of the redirect func executes in callback, it need to run in Angular zone
          console.log('connected and requested route is public', requestedRoute, 'redirect to default : ', this.defaultAuthRoute);
          this._ngZone.run(() => this.router.navigate(['/' + this.defaultAuthRoute]));
        } else {
          console.log('connected and requested route is private => ok', requestedRoute);
          this._ngZone.run(() => this.router.navigate(['/' + requestedRoute]));
        }
      }
    })
    

    this.webcomRef.addAuthCallback(this.localAuthCB);
  }

  ngOnDestroy() {

    console.log('auth guard DEStroyed');
    this.webcomRef.removeAuthCallback(this.localAuthCB);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {

    const requestedRoute = route.url.toString();
    console.log('canActivate requestedRoute=', requestedRoute);

    if (this.webcomRef.authState) { // If the user is connected
      console.log('canactivate: user connected.');
      if (this.publicRoutes.includes(requestedRoute)) {
        console.log('this is a public route => redirect to ', this.defaultAuthRoute);
        return this.router.parseUrl('/' + this.defaultAuthRoute);
      } else {
        console.log('this is a private route');
        return true;
      }
    }else{
      console.log('canactivate: user not connected');
      if (this.publicRoutes.includes(requestedRoute)) {
        console.log('this is a public route');
        return true;
      }else{
        console.log('this is a private route');
        return this.router.parseUrl('/' + this.defaultNotAuthRoute);
      }
    }
  }
}
