import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Agile retro';
  constructor(
    @Inject ('baseWebcomref') private webcomRef
  ) { }
  ngOnInit() { }
}
