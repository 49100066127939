import { Router, NavigationEnd } from '@angular/router';
import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-head',
  templateUrl: './head.component.html',
  styleUrls: ['./head.component.scss']
})
export class HeadComponent implements OnInit {

  private displayNav = false;
  private isLargeDocument = false;
  private screenWidthThreshold = 752;
  private lastRetroVisited: object;
  constructor(
    @Inject('webcom_img_url') private webcomImgUrl,
    @Inject ('baseWebcomref') private webcomRef,
    @Inject ('toastSvc') private toastService,
    private _ngZone: NgZone,
    private router: Router,
  ) { }

  ngOnInit() {
    this.router.events
    .pipe(filter(e => e instanceof NavigationEnd))
    .subscribe((val) => {
      const segment = this.router['rawUrlTree']['root']['children']['primary']['segments'][0];

      if (segment['path'] === 'retroscreen') {
        this._ngZone.run(()=> this.lastRetroVisited = {
          retroId: segment['parameters']['retroId'],
          ownerId: segment['parameters']['ownerId'],
        });
      }
    });

    this._ngZone.run(()=> this.isLargeDocument = document.body.clientWidth > this.screenWidthThreshold);
    window.addEventListener('resize', ()=> {
      this._ngZone.run(()=> this.isLargeDocument = document.body.clientWidth > this.screenWidthThreshold);
    });
  }

  logOut() {
    this.webcomRef.logout((err) => {
      if (err) {
        this.toastService.showDanger(`Couldn't logout. ERRor= ${err}`);
      }
    })
    this.displayNav = false;
  }
}
