import { Component, OnInit, Inject, ViewChild, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { escapeHtmlSpecialchars } from '../utilities/utilities';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  private submitOnPwdBtnEnabled = false;
  private submitOnPhoneBtnEnabled = false;
  private signupWithPassword = true;
  @ViewChild('pwdSignupForm', {static: false}) private pwdSignupFormRef: NgForm;
  @ViewChild('phoneSignupForm', {static: false}) private phoneSignupFormRef: NgForm;
  constructor(
    @Inject('webcom_img_url') private webcomImgUrl,
    @Inject('baseWebcomref') private webcomRef,
    @Inject('idHandler') private idHandler,
    @Inject('toastSvc') private toastService,
    private router: Router,
    private _ngZone: NgZone
  ) { }

  ngOnInit() {  }

  notifyChanged(provider: string, identity: string, password?: string|null, repeatedPassword?: string|null) {
    if (provider === 'password') {
      const mail = identity;
      const pwd = password;
      const rPwd = repeatedPassword;

      if (!!mail && !!pwd && !!rPwd) {
        this.submitOnPwdBtnEnabled = pwd === rPwd; // condition to enable submit button
      }
    }
  }

  signupOnSubmit(mail: string, password: string): Promise<boolean> {
    this.submitOnPwdBtnEnabled = false;
    this.toastService.showStandard('Processing...');
    if(!this.idHandler.matchesMailFormat(mail)) {
      this.submitOnPwdBtnEnabled = true;
      this.toastService.showstandard("createUser : bad format"); return Promise.reject(false);
    } 
    
    const details = {
      id: mail,
      password: password
    };
    return this.webcomRef.addIdentity("password", details)
    .then(()=>{
      this.toastService.showSuccess('Your account have been saved succefully.\nA mail confirmation is required to use the application.');
      this.router.navigate(['/login']);
      return true;
    })
    .catch((error) => {
      this.submitOnPwdBtnEnabled = true;
      switch (error.code) {
        case 'EXISTING_IDENTITY':
          this.toastService.showStandard('Identity already in use!'); break;
        case 'INVALID_EMAIL':
          this.toastService.showStandard('Identity format is not valid!'); break;
        default:
          this.toastService.showDanger('Account creation failed: ' + error); break;
      }
    });
  }

  signupOnSubmitPhone(msisdn: string): Promise<boolean> {
    this.submitOnPhoneBtnEnabled = false;
    this.toastService.showStandard('Sending OTP ...')
    if(!this.idHandler.matchesMsisdnFormat(msisdn)) {
      this.toastService.showStandard("createUser : bad format"); return Promise.reject(false);
    }
    return this.webcomRef.addIdentity("phone", { id: msisdn })
    .then((auth)=>{
      const userOtp = escapeHtmlSpecialchars(window.prompt('Please enter the message you have received to your phone :'));
      this.toastService.showStandard('Processing ...')

			if (!userOtp || !/^\d{4}$/.test(userOtp)) { // four-numbers format
        this.submitOnPhoneBtnEnabled = true;
        this.toastService.showStandard('Bad format.');
      } else this.webcomRef.verifyIdentity('phone', msisdn, {auth: auth, password: userOtp})
      .then(()=>{
        this.toastService.showSuccess('Your account have been saved succefully.');
      })
      return true;
    })
    .catch((error) => {
      switch (error.code) {
        case 'EXISTING_IDENTITY':
          this.toastService.showStandard('Identity already in use!'); break;
        case 'INVALID_EMAIL':
          this.toastService.showStandard('Identity format is not valid!'); break;
        default:
          this.toastService.showDanger('Account creation failed: ' + error); break;
      }
    });
  }

  initializePwdForm() {
    this.pwdSignupFormRef.setValue({password: '', repeatedPassword: '', mail: this.pwdSignupFormRef.value.mail})
  }
}
