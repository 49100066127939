import { escapeHtmlSpecialchars } from './../utilities/utilities';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	private loginWithPassword = true;
	private pwdLoginBtnEnabled = true;
	private phoneLoginBtnEnabled = true;
  constructor(
    @Inject('webcom_img_url') private webcomImgUrl,
    @Inject ('baseWebcomref') private webcomRef,
    @Inject('idHandler') private idHandler,
    @Inject ('toastSvc') private toastService,
  ) { }

	ngOnInit() { }

  loginOnSubmit(mail: string, password: string): boolean {
		this.pwdLoginBtnEnabled = false;
		this.toastService.showStandard('Processing ...');

		// TODo : is mail format + provider en dur dans le login
		if(!this.idHandler.matchesMailFormat(mail)) {
			this.pwdLoginBtnEnabled = true;
			this.toastService.showDanger("login : bad format"); return false;
		}

		const credentials = {
			id: mail,
			password: password
		};
		this.doLogin("password", credentials)
		.then(()=>{
			this.toastService.showSuccess('login: user connected successfully.');
			return true;
		}).catch((err)=> {
			this.pwdLoginBtnEnabled = true;
		})
	}


	loginOnSubmitPhone(msisdn): boolean {
		this.phoneLoginBtnEnabled = false;
		this.toastService.showStandard('Processing ...');

		if(!this.idHandler.matchesMsisdnFormat(msisdn)) {
			this.phoneLoginBtnEnabled = true;
			this.toastService.showStandard("login : bad format"); return false;
		}
		this.sendAndConfirmChallenge(msisdn)
		.then((phoneBasedCreds)=> this.doLogin("phone", {
			id: msisdn,
			challenge: phoneBasedCreds['challenge'],
			password: phoneBasedCreds['otp']
		}))
		.then(()=>{
			this.toastService.showSuccess('login: user connected successfully.');
			return true;
		})
		.catch((err)=>{
			this.phoneLoginBtnEnabled = true;
		});
	}


	doLogin(provider: string, credentials: object) {
		console.log('try to log in with ', credentials);

		return this.webcomRef.authInternally(provider, credentials)
		.then((auth) => {
			console.log('login: loginOnSubmit: auth==', auth);
		})
		.catch((err)=>{
			const errorMsg = `login not performed. err: ${err}`;
			this.toastService.showDanger(errorMsg);
      throw err;
		})
	}


  sendAndConfirmChallenge(phoneId: string): Promise<object> {
    return this.webcomRef.sendOtp("phone", phoneId)
    .then(challenge => {
			const userOtp = escapeHtmlSpecialchars(window.prompt('Please enter the message you have received to your phone :'));
			if (userOtp && /^\d{4}$/.test(userOtp)) { // four-numbers format
				return {challenge: challenge, otp: userOtp};
			}
			return Promise.reject('Bad format.');
    });
	}
	askTheUserForTheReceivedOtp() {
		return null;
	}

	sendResetOnclick(identity: string) {
		if(!!identity) {
			if (confirm(`An email will be sent to : ${identity}. Confirm ?`)) {
				this.webcomRef.sendPasswordResetCode('password', identity)
				.then(()=>this.toastService.showSuccess("The email has been sent successfully."))
				.catch((err)=>this.toastService.showDanger(`An error has occurred : ${err}`))
			}
		} else this.toastService.showStandard('Please enter your email address in the email field.');
	}
}
