
export const availableLangs = ['fr', 'en'];
export const availableSpacesRules = ['Member', 'Administrator'];

export const isEmpty = function(obj: Object): boolean {

	for (const key in obj) {
		if (obj.hasOwnProperty(key)) { return false; } // This object is NOT empty.
	}
	return true; // This object is empty.
}

export const generateRandomId = function( contentType: string, idSize: number ): string {
	const chars = 'abcdefghijklmnopqrstuvwwyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
	const numbers = '0123456789';
	
	let newId: string;
	let dataPicker: string;

	switch (contentType) {
		case 'CHARS':
			dataPicker = chars; break;
		case 'CHARS_AND_NUMBERS':
			dataPicker = chars + numbers; break;
		case 'NUMBERS':
			dataPicker = numbers; break;
		default: break;
	}

	for (let i = 0; i < idSize; i++) {
		newId = newId + dataPicker[ Math.floor( Math.random() * (dataPicker.length - 2) ) ] // random char between 0 and dataPicker's size -1
	}
	return newId;
}

export const getKeys = function(o: object|null): Array<any> {
	if (!o) return [];
	return Object.keys(o);
}
export const getEntries = function(o: object|null): Array<any> {
	if (!o) return [];
	return Object.entries(o);
}
export const getLength = function(o: object|null): number {
	if (!o) return 0;
	return Object.keys(o).length;
}
export const escapeHtmlSpecialchars = function(unsafe: string) {
	if (!unsafe) return null;
	return unsafe.replace(/[&<>'"]/g, "&amp;");
}
	
