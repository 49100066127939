import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IdentityHandlerService {
  
  private localAuthCB: Function
  constructor(
    @Inject ('baseWebcomref') private webcomRef
  ) { 

    const authCallback = (error, auth)=>{

      if (error || !auth) { // if user is NOT connected
        if (error) { console.log('Error auth: ' + error); } else { console.log('Not connected'); }
      } else {
        const userIdentity = auth.providerUid;
        console.log('Authenticated: ' + userIdentity);
        // TODO : if the user have been invited to collaborate to a retro, the application must register her.his account to the retro_owner.
  
        this.setUserIdentityInDatabase(userIdentity, auth.uid);
      }
    }
    this.localAuthCB = authCallback

    this.webcomRef.addAuthCallback(this.localAuthCB);
  }

  ngOnDestroy() {

    console.log('identity service DEStroyed');
    this.webcomRef.removeAuthCallback(this.localAuthCB);
  }

  /**
   * getIdentityFromDatabase
   * Checks if the identity of the user exists in database.
   * @param identity : email or msisdn
   * @returns Promise<{ foundId: user_identity, foundUid: user_uid }>
   */
  getIdentityFromDatabase(identity: string): Promise<Object> {

    return new Promise((resolve, reject)=>{
      this.webcomRef
      .child('identities/#' + this.encodeEmailId(identity))
      .once('value', (snap)=>{
        const foundUid = snap.val();
        if(foundUid) resolve({foundId: snap.name(), foundUid: foundUid})
        else resolve(null)
      });
    })
  }

  encodeEmailId(id: string): string{
    return encodeURIComponent(id).replace(/[.]+/g, '%2E');
    /* ex: john.doe@example.com
       >>  john%2Edoe%40example%2Ecom
    */
  }

  decodeMailId(encodedId: string): string {
    return decodeURIComponent(encodedId.replace(/%2E+/g, '.'));
  }

  /**
   * setUserIdentityInDatabase
   * sets the user's identity in database
   * @param userIdentity : email or msisdn
   */
  setUserIdentityInDatabase(userIdentity: string, userUid: string): Promise<string> {
  
    let provider: string;
    let providerPath: string;
    let encodedUserIdentity = userIdentity;
    if (this.matchesMailFormat(userIdentity)) {
      provider = "password";
      providerPath = "email";
      encodedUserIdentity = this.encodeEmailId(userIdentity);
    }
    else if (this.matchesMsisdnFormat(userIdentity)) {
      provider = "phone";
      providerPath = "msisdn";
    }
    else {
      const errMsg = "Create identity : NO PROVIDERS";
      console.log(errMsg);
      return Promise.reject(errMsg);
    }
  
		return new Promise((res, rej)=>{
			this.webcomRef.child(`identities/#${encodedUserIdentity}`).set(userUid, (err)=>{
				if (err) {
					rej(`idhandler: identity not created. Error: ${err}`);
				}
				else {
					const successMsg = "idhandler: the identity of the user have been initialized.";
					console.log(successMsg);
					res(successMsg);
				}
			})
		})
  }

  matchesMailFormat( identity: string ): boolean {
    /* format :
    example@example.fr    
    example@example.com    
    example.example@example.com    
    example@example.example.com    
    example.example@example.example.com    
    */
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(identity);
  }
  
  matchesMsisdnFormat( identity: string ): boolean {
    /* format :
      33 xxx xxx xxx
    */
    return /^33\d{9}$/.test(identity);
  }
}
