import { getKeys } from '../utilities/utilities';
import { Component, OnInit, Inject, ChangeDetectorRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-listretros',
  templateUrl: './listretros.component.html',
  styleUrls: ['./listretros.component.scss']
})
export class ListretrosComponent implements OnInit {

  private userRetrosRef;
  private receivedAuthRef;
  private userRetrosOnChildaddedCallback: Function;
  private userRetrosOnChildremovedCallback: Function;
  private otherRetrosOnChildaddedCallback: Function;
  private otherRetrosOnChildremovedCallback: Function;
  private ownRetrosList = {};
  private otherRetrosList = {};
  private getKeys = getKeys;

  private editingNewRetro = false;
  private editedExistingRetroId: string;

  constructor(
    @Inject('baseWebcomref') private webcomRef,
    @Inject('toastSvc') private toastService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private _ngZone: NgZone
  ) {
    this.userRetrosRef = this.webcomRef.child(`spaces/${this.webcomRef.authState.uid}/retros`);
    this.receivedAuthRef = this.webcomRef.child(`spaces/${this.webcomRef.authState.uid}/receivedAuthorizations`);
  }
  
  ngOnInit(): void {
    this.fetchAndSubscribeToOwnRetros();
    this.fetchAndSubscribeToOtherRetros();
  }
  ngOnDestroy() {
    this.userRetrosRef.off('child_added', this.userRetrosOnChildaddedCallback);
    this.userRetrosRef.off('child_removed', this.userRetrosOnChildremovedCallback);
    this.receivedAuthRef.off('child_added', this.otherRetrosOnChildaddedCallback);
    this.receivedAuthRef.off('child_removed', this.otherRetrosOnChildremovedCallback);
    for (const retroId in this.ownRetrosList) {
      this.ownRetrosList[retroId]['titleSubscriptionRef']
      .off('value', this.ownRetrosList[retroId]['titleSubscriptionCallback']);
    }
    for (const retroId in this.otherRetrosList) {
      this.otherRetrosList[retroId]['titleSubscriptionRef']
      .off('value', this.otherRetrosList[retroId]['titleSubscriptionCallback']);
    }
  }
  fetchAndSubscribeToOwnRetros() {
    this.userRetrosOnChildaddedCallback = this.userRetrosRef
    .on('child_added', (snap)=> this.onOwnRetroAdded(this, snap.name()));
    
    this.userRetrosOnChildremovedCallback = this.userRetrosRef
    .on('child_removed', (snap)=> this.onRetroRemoved(snap.name(), this.ownRetrosList));
  }
  fetchAndSubscribeToOtherRetros() {
    this.otherRetrosOnChildaddedCallback = this.receivedAuthRef
    .on('child_added', (snap)=> this.onOtherRetroAdded(this, snap.name(), snap.val()));
    
    this.otherRetrosOnChildremovedCallback = this.receivedAuthRef
    .on('child_removed', (snap)=> this.onRetroRemoved(snap.name(), this.otherRetrosList));
  }

  onOwnRetroAdded(self: ListretrosComponent, retroId: string){
    self.ownRetrosList[retroId] = {};
    self.subscribeToRetroTitleChanges(
      retroId,
      self.ownRetrosList[retroId],
      self.webcomRef.authState.uid
    )
  }
  onOtherRetroAdded(self: ListretrosComponent, retroId: string, retroOwnerId: string){
    self.otherRetrosList[retroId] = {};
    self.otherRetrosList[retroId]['owner'] = retroOwnerId;
    self.subscribeToRetroTitleChanges(
      retroId,
      self.otherRetrosList[retroId],
      retroOwnerId
    )
  }
  onRetroRemoved(retroId: string, retroList: object){
    retroList[retroId]['titleSubscriptionRef']
    .off('value', retroList[retroId]['titleSubscriptionCallback']);
    delete retroList[retroId];
    this.changeDetectorRef.detectChanges();
  }

  subscribeToRetroTitleChanges(retroId: string, retroData: object, retroOwnerId: string){
    retroData['titleSubscriptionRef'] = this.webcomRef.child(`spaces/${retroOwnerId}/retros/${retroId}/title`);
    retroData['titleSubscriptionCallback'] = retroData['titleSubscriptionRef']
    .on('value', (snap)=>{
      retroData['title'] = snap.val();
      this.changeDetectorRef.detectChanges();
    });
  }


  displayEditRetroTitleField(retroId) {
    this.editedExistingRetroId = retroId;
    this.changeDetectorRef.detectChanges();
  }

  addRetro(retroTitle: string) {
    if(retroTitle) {
      const newRetroRef = this.userRetrosRef
      .push({'title': retroTitle}) // push new retro data
      
      // push three boards by default
      const boardsRef = newRetroRef.child('boards'); // get the boards childnode reference of this retro
      boardsRef.push({ title: 'Went well' })
      boardsRef.push({ title: 'To improve' })
      boardsRef.push({ title: 'Suggestions' })
    }
    this.editingNewRetro = false;
    this.changeDetectorRef.detectChanges();
  }
  editRetro(retroTitle: string, retroId: string) {
    if(retroTitle || confirm("The retro's new title you entered is blank. Update anyway ?")) {
      this.userRetrosRef.child(`${retroId}/title`)
      .set(retroTitle, (err)=>{
        if(err) this.toastService.showDanger(err);
      });
    }
    this.editedExistingRetroId = '';
    this.changeDetectorRef.detectChanges();
  }
  removeRetro(retroId: string): void {
    if (confirm(`Your about to delete retro : ${this.ownRetrosList[retroId]['title']}. Are you sure ?`)) {
      this.userRetrosRef.child(retroId).remove();
    }
  }

  foldRetroTemplate(templateName: string) {
    const content = document.getElementById(templateName);
    if (!content.style.display || content.style.display === "block") {
      content.style.display = "none";
    } else {
      content.style.display = "block";
    }
  }

  openRetro(retroId: string, retroOwnerId?: string) {
    if (!retroOwnerId) retroOwnerId = this.webcomRef.authState.uid;
    this._ngZone.run(() => this.router.navigate(['retroscreen', {
      retroId: retroId,
      ownerId: retroOwnerId,
    }]));
  }
}
