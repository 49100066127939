import { getKeys, getEntries, getLength } from './../utilities/utilities';
import { Component, OnInit, Inject, NgZone, ElementRef, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { SwiperComponent } from "ngx-useful-swiper";
import { SwiperOptions } from "swiper";

@Component({
  selector: 'app-retro-screen',
  templateUrl: './retro-screen.component.html',
  styleUrls: ['./retro-screen.component.scss']
})
export class RetroScreenComponent implements OnInit {
  static readonly DefaultRetroData = {};

  private retroRef;
  private collabsRef;
  private retroSubscriptionCallback;
  private contactsSubscriptionCallback;
  private retroData: Object = RetroScreenComponent.DefaultRetroData;
  private contactsData: Object = {};
  private collabsNicknames: Object = {};
	private retroId: string
  private ownerId: string
  private getKeys = getKeys;
  private getEntries = getEntries;
  private getLength = getLength;
  private displayNewBoardForm = false;
  private suppliedBoardId = "";
  private displayCollabsPanel = false;
  private displayContactsPanel = false;
  
  @ViewChild('boardSwiper', { static: false }) private boardSwiper: SwiperComponent;

  private config: SwiperOptions = {
    simulateTouch : this.isMobileOrTabletDevice(),
    allowTouchMove: true,
    breakpoints: {
      2400: { slidesPerView: 6 },
      2000: { slidesPerView: 5 },
      1600: { slidesPerView: 4 },
      1024: { slidesPerView: 3 },
      600:  { slidesPerView: 2 },
      400:  { slidesPerView: 1 }
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 25,
  };



  constructor(
    @Inject('baseWebcomref') private webcomRef,
    @Inject('toastSvc') private toastService,
    private router: Router,
    private route: ActivatedRoute,
    private _ngZone: NgZone,
  ) {  }


  ngOnInit(): void {
		this.retroId = this.route.snapshot.paramMap.get('retroId');
    this.ownerId = this.route.snapshot.paramMap.get('ownerId');

    this.retroRef = this.webcomRef.child(`spaces/${this.ownerId}/retros/${this.retroId}`);
    this.collabsRef = this.retroRef.child('givenAuthorizations');

    this.retroSubscriptionCallback = this.retroRef.on('value', (dataSnapshot) => {
      const newValue = dataSnapshot.val();
      if (!newValue) {
        this._ngZone.run(() => this.retroData = RetroScreenComponent.DefaultRetroData);
      } else {
        this.fetchCollabsNicknames(newValue['givenAuthorizations'])
        this._ngZone.run(() => this.retroData = newValue);
      }
      console.log("wha happen ", newValue);
    });
    this.contactsSubscriptionCallback = this.webcomRef
    .child(`spaces/${this.webcomRef.authState.uid}/contacts`)
    .on('value', (snap)=>{
      const newValue = snap.val();
      if (!newValue)  this._ngZone.run(() => this.contactsData = {});
      else            this._ngZone.run(() => this.contactsData = newValue);
    })
  }
  ngOnDestroy() {
    console.log("ondestroy");
    this.retroRef.off('value', this.retroSubscriptionCallback);
    this.webcomRef.off('value', this.contactsSubscriptionCallback);
  }

  editRetroTitle(newRetroTitle: string) {
    this.retroRef.child(`title`)
    .set(newRetroTitle, (err)=>{
      if(err) this.toastService.showDanger(err);
    });
  }
  removeRetroOnClick() {
    if(confirm(`Are you sure you want to delete this retro '${this.retroData['title']}' ? (This action is irreversible.)`)) this.retroRef.remove((err)=>{
      if(err) this.toastService.showDanger(err);
      else this._ngZone.run(() => this.router.navigate(['/listretros']));
    });
  }

  addBoardOnSubmit(boardTitle: string) {
    this.retroRef.child('boards')
    .push({'title': boardTitle}, (err)=>{
      if(err) this.toastService.showDanger(err);
      this._ngZone.run(() => this.displayNewBoardForm = false);
    });
  }
  editBoardTitle(newBoardTitle: string, boardId: string) {
    this.retroRef.child(`boards/${boardId}/title`)
    .set(newBoardTitle, (err)=>{
      if(err) this.toastService.showDanger(err);
    });
  }
  removeBoardOnClick(boardId: string) {
    if(confirm(`Are you sure you want to delete the board '${this.retroData['boards'][boardId]['title']}' ? (This action is irreversible.)`)) this.retroRef.child(`boards/${boardId}`).remove();
  }

  addCard(newCardContent: string, boardId: string) {
    this.retroRef.child(`boards/${boardId}/cards`)
    .push({'content': newCardContent}, (err)=>{
      if(err) this.toastService.showDanger(err);
      else this._ngZone.run(()=> this.suppliedBoardId = '');
    });
  }
  editCardOnSubmit(newCardContent: string, boardId: string, cardId: string, elementRef: HTMLParagraphElement) {
    const updateCardContent = ()=>{
      this.retroRef.child(`boards/${boardId}/cards/${cardId}/content`)
      .set(newCardContent, (err)=>{
        if(err) this.toastService.showDanger(err);
      });
    }

    if(!!!newCardContent) this.restoreAncientCardValue(boardId, cardId, elementRef);
    else if (newCardContent !== this.retroData['boards'][boardId].cards[cardId].content) updateCardContent();
  }
  restoreAncientCardValue(boardId: string, cardId: string, elementRef: HTMLParagraphElement) {
    this._ngZone.run(() => elementRef.textContent = this.retroData['boards'][boardId].cards[cardId].content);
  }
  removeCardOnClick(boardId: string, cardId: string) {
    if(confirm(`Are you sure you want to delete the card ?`)) this.retroRef.child(`boards/${boardId}/cards/${cardId}`).remove();
  }

  isCardLiked(boardId: string, cardId: string){
    const userId = this.webcomRef.authState.uid;
    return this.isCardLikedUserId(boardId, cardId, userId);
  }

  isCardLikedUserId(boardId: string, cardId: string, userId: string){
    return this.retroData['boards'][boardId].cards[cardId].votes && this.retroData['boards'][boardId].cards[cardId].votes[userId];
  }

  toggleVoteOnClick(boardId: string, cardId: string) {
    const userId = this.webcomRef.authState.uid;
    if(this.isCardLikedUserId(boardId, cardId, userId)){
      this.retroRef.child(`boards/${boardId}/cards/${cardId}/votes/${userId}`).remove();
    } else {
      this.retroRef.child(`boards/${boardId}/cards/${cardId}/votes/${userId}`)
      .set(new Date().toJSON(), (err)=>{
        if(err) this.toastService.showDanger(err);
      });
    }
  }

  addCollaborator(idObj: object) {
    if (this.retroData['givenAuthorizations'] && this.retroData['givenAuthorizations'][idObj["foundUid"]]) {
      this.toastService.showStandard(`${idObj['foundId']} collaborates already to this retro.`)
      return Promise.resolve();
    }
    return new Promise((resolve, reject)=>{
      // Add collab at owner's side
      this.collabsRef.child(idObj["foundUid"])
      .set((this.ownerId), ()=> {

        // add authorization to the retro at collab's side
        this.webcomRef.child(`spaces/${idObj["foundUid"]}/receivedAuthorizations/${this.retroId}`)
        .set(this.ownerId, ()=> resolve());
      });
    })
  }
  
  removeCollaborator(userUid) {
    if (confirm(`${userUid} will quit this retro. Confirm ?`)) {
      return new Promise((resolve, reject)=>{
        this.collabsRef.child(userUid)
        .remove(()=>{
          this.webcomRef.child(`spaces/${userUid}/receivedAuthorizations/${this.retroId}`)
          .remove(()=> resolve());
        });
      });
    } else return Promise.reject();
  }

  leaveRetro() {
    this.removeCollaborator(this.webcomRef.authState.uid)
    .then(()=>this._ngZone.run(() => this.router.navigate(['/listretros'])))
    .catch(()=>null);
  }
  sortCardIdsByVote(o: object, boardId: string): Array<string> {
    if (!o) return [];
    const getCardVotesNumber = (cardId: string) =>{
      return this.getLength( this.retroData['boards'][boardId]['cards'][cardId]['votes']);
    }
    return this.getKeys(o).sort((cardId_a, cardId_b) => {
      return getCardVotesNumber(cardId_b) - getCardVotesNumber(cardId_a);
    });
  }

  fetchCollabsNicknames(collabsObject) {
    const promiseArray = [];
    for (const collabId in collabsObject) {
      promiseArray.push(this.findNickname(collabId)
      .then((collabNickname)=>{
        this._ngZone.run(()=> this.collabsNicknames[collabId] = collabNickname);
      }))
    }
    return Promise.all(promiseArray);
  }

  findNickname(userId) {
    return new Promise((res,rej)=>{
      this.webcomRef.child(`spaces/${userId}/nickname`)
      .once('value', (snap)=>{
        if (snap.val()) res(snap.val());
        else res('No nickname');
      })
    });
  }

  nextSlide() {
    this.boardSwiper.swiper.slideNext();
  }

  previousSlide() {
    this.boardSwiper.swiper.slidePrev();
  }
  
  slideToThis(index) {
    this.boardSwiper.swiper.slideTo(index);
  }

  isMobileOrTabletDevice() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
}
