import { getKeys } from '../utilities/utilities';
import { Component, Inject, NgZone, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent {
  static readonly DefaultData = {};

  @Output() private externalAddCollab: EventEmitter<object> = new EventEmitter;
  private getKeys = getKeys;
  @ViewChild('cSearchBar', {static: false}) private cSearchBarRef: HTMLInputElement;
  private foundCollabs: object[] = [];

  constructor(
    @Inject('idHandler') private idHandler,
    @Inject('baseWebcomref') private webcomRef,
    @Inject('toastSvc') private toastService,
    private _ngZone: NgZone,
  ) {  }

  browseIdentity(userId) {
    if (!userId) return false;
    this.idHandler.getIdentityFromDatabase(userId).then((id)=> {
      if(id) {
        // a msisdn is not encoded, and encoded email doesn't match email format
        if(!this.idHandler.matchesMsisdnFormat(id.foundId)) id.foundId = this.idHandler.decodeMailId(id.foundId);
        this._ngZone.run(()=>this.foundCollabs = [id]);
      }
      else {
        this.toastService.showStandard('No existing user found.');
        this._ngZone.run(()=> this.foundCollabs = []);
      }
    })
  }

  addCollaborator(idObj) {
    if(idObj['foundUid'] === this.webcomRef.authState.uid) {
      this.toastService.showStandard("You cannot add yourself !");
    } else this.externalAddCollab.emit(idObj);
    this._ngZone.run(()=>this.foundCollabs = []);
    this._ngZone.run(()=> this.cSearchBarRef.value = '');
  }
}
