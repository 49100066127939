import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxUsefulSwiperModule } from "ngx-useful-swiper";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { RouterModule, Routes } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { UsereditComponent } from './useredit/useredit.component';

import Webcom from 'webcom/webcom';
import { AuthGuard } from './guards/auth.guard';
import { HeadComponent } from './head/head.component';
import { ListretrosComponent } from './listretros/listretros.component';
import { RetroScreenComponent } from './retro-screen/retro-screen.component';
import { IdentityHandlerService } from './services/identity-handler.service';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { ToastService } from "./services/toast/toast-service.service";
import { ToastContainerComponent } from './services/toast/toast-container.component';


const loginPath = 'login';
const signUpPath = 'signup';
const listRetroPath = 'listretros';
const profilePath = 'useredit';
const retroscreenPath = 'retroscreen';
const publicRoutes: (string)[] = [loginPath, signUpPath];
const defaultNotAuthRoute = loginPath;
const defaultAuthRoute: string = listRetroPath;


// default routes
const routes: Routes = [
  { path: loginPath, component: LoginComponent, canActivate: [AuthGuard] },
  { path: signUpPath, component: SignupComponent, canActivate: [AuthGuard] },
  { path: profilePath, component: UsereditComponent, canActivate: [AuthGuard] },
  { path: listRetroPath, component: ListretrosComponent, canActivate: [AuthGuard] },
  { path: retroscreenPath, component: RetroScreenComponent, canActivate: [AuthGuard] },
  { path: `${retroscreenPath}/:retroId'`, component: RetroScreenComponent, canActivate: [AuthGuard] },
  { path: `${retroscreenPath}/:retroId/:ownerId'`, component: RetroScreenComponent, canActivate: [AuthGuard] },
  { path: '',   redirectTo: '/' + listRetroPath, pathMatch: 'full' },
];

const baseWebcomRef = new Webcom('https://io.datasync.orange.com/base/example-agility');
const idHandler = new IdentityHandlerService(baseWebcomRef)
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    UsereditComponent,
    HeadComponent,
    ListretrosComponent,
    RetroScreenComponent,
    SearchbarComponent,
    ToastContainerComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot(routes),
    NgbModule,
    NgxUsefulSwiperModule
  ],
  providers: [
    { provide: 'publicRoutes', useValue: publicRoutes},
    { provide: 'defaultNotAuthRoute', useValue: defaultNotAuthRoute},
    { provide: 'defaultAuthRoute', useValue: defaultAuthRoute},
    { provide: 'baseWebcomref', useValue: baseWebcomRef },
    { provide: 'webcom_img_url', useValue: 'assets/img/aretro_logo.svg' },
    { provide: 'idHandler', useValue: idHandler },
    { provide: 'toastSvc', useValue: new ToastService() },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
