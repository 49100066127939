import { getKeys } from './../utilities/utilities';
import { Component, OnInit, Inject, ChangeDetectorRef, NgZone } from '@angular/core';

@Component({
  selector: 'app-useredit',
  templateUrl: './useredit.component.html',
  styleUrls: ['./useredit.component.scss']
})
export class UsereditComponent implements OnInit {

  static readonly DefaultUserData = {nickname: ''};

  private userRef;
  private contactsRef;
  private userSubscriptionRef;
  private contactsSubscriptionCallback;
  private contactsNicknames: Object = {};
  private userData: Object = UsereditComponent.DefaultUserData; // should it be typed ?
  private idRequestFormDisplayed = false;
  private getKeys = getKeys;

  constructor(
    @Inject('baseWebcomref') private webcomRef,
    @Inject('toastSvc') private toastService,
    private changeDetectorRef: ChangeDetectorRef,
    private _ngZone: NgZone,
  ) {
    this.userRef = this.webcomRef.child(`spaces/${this.webcomRef.authState.uid}`);
    this.contactsRef = this.userRef.child('contacts');
   }

  ngOnInit(): void {
    this.userSubscriptionRef = this.userRef.on('value', (dataSnapshot) => {
      if (!dataSnapshot.val()) {
        this.userData = UsereditComponent.DefaultUserData;
      } else {
        this.userData = dataSnapshot.val();
      }
      this.changeDetectorRef.detectChanges();
    });
    this.contactsSubscriptionCallback = this.contactsRef.on('value', (dataSnapshot) => {
      const newValue = dataSnapshot.val();
      if (newValue){
        this.fetchContactsNicknames(newValue)
      }
    })
    // console.log('test tab : ', this.contactsNicknames)
  }
  ngOnDestroy() {
    this.userRef.off('value', this.userSubscriptionRef);
    this.contactsRef.off('value', this.contactsSubscriptionCallback);
  }

  editNicknameOnSubmit(userNickname: string) {

    this.userRef.child("nickname").set(userNickname,(err)=>{
      if (err) {
        this.toastService.showDanger(`Error when updating nickname : ${err}`);
      } else this.toastService.showSuccess('nickname updated.')
    });
  }

  editPasswordOnSubmit(oldPwd: string, newPwd: string, rNewPwd: string) {

    if (newPwd === rNewPwd) {
      this.webcomRef.changePassword(this.webcomRef.authState.providerUid, oldPwd, newPwd)
      .then((err) => {
        if (err) this.toastService.showDanger('USEREDIT webcom editpwd err==', err);
        else this.toastService.showSuccess("The new password has been applied successfully.")
      }).catch((err)=> this.toastService.showDanger(`USEREDIT webcom editpwd err== ${err}`))// Because at least 1 error is returned by promise rejection !
    } else this.toastService.showStandard("The password aren't the same.");
  }

  addContact(idObj: string) {
    if (this.userData['contacts'] && this.userData['contacts'][idObj["foundUid"]]) {
      this.toastService.showStandard(`${idObj['foundId']} belongs already to your contacts.`)
      return Promise.resolve();
    }
    return new Promise((resolve, reject)=>{
      const newData = {
        'identity': idObj["foundId"],
        'familyName': idObj["foundId"],
        'givenName': idObj["foundId"],
      }
      this.contactsRef.child(`${idObj["foundUid"]}`)
      .set(newData, ()=> resolve());
    })
  }
  removeContact(userUid: string) {
    if (confirm(`Remove ${userUid} from your contact ?`)) {
      this.contactsRef.child(userUid).remove();
    }
  }

  displayIdrequestForm() {
    this.idRequestFormDisplayed = !this.idRequestFormDisplayed;
  }

  fetchContactsNicknames(contactsObject) {
    const promiseArray = [];
    for (const contactId in contactsObject) {
      promiseArray.push(this.findNickname(contactId)
      .then((collabNickname)=>{
        this._ngZone.run(()=> this.contactsNicknames[contactId] = collabNickname);
      }))
    }
    return Promise.all(promiseArray);
  }

  findNickname(userId) {
    return new Promise((res,rej)=>{
      this.webcomRef.child(`spaces/${userId}/nickname`)
      .once('value', (snap)=>{
        if (snap.val()) res(snap.val());
        else res('No nickname');
      })
    });
  }

  isPhoneUser(id: string){
    const reg = new RegExp("^33([0-9]{9,9})$");
    return reg.test(id);
  }
}
